import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Loader = () => {
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < 100) {
        setCounter(counter + 1);
      }
    }, 30);

    return () => clearInterval(interval);
  }, [counter]);

  // send get request for BASE_URL ON MOUNT
  useEffect(() => {
    fetch(`${BASE_URL}/cv`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="loading-page">
      <div className="counter">
        <p>{t("preLoader")}</p>
        <h1>{counter}%</h1>
        <hr style={{ width: `${counter}%` }} />
      </div>
    </div>
  );
};

export default Loader;
