const BASE_URL = process.env.REACT_APP_BASE_URL;

const authProvider = {
  // Login method
  login: async ({ username, password }) => {
    const request = new Request(`${BASE_URL}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include", // Include cookies in the request
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error("Invalid username or password");
    }
    return Promise.resolve();
  },

  // Logout method
  logout: async () => {
    const request = new Request(`${BASE_URL}/api/auth/logout`, {
      method: "POST",
      credentials: "include", // Include cookies in the request
    });

    return fetch(request)
      .then(() => Promise.resolve())
      .catch(() => Promise.reject());
  },

  // Check authentication method
  checkAuth: async () => {
    const request = new Request(`${BASE_URL}/api/auth/checkAuth`, {
      method: "GET",
      credentials: "include", // Include cookies in the request
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject();
        }
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  },

  // Handle errors
  checkError: async (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // Get user identity
  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "Ahmed Elfayomy",
      avatar: "/icon.png",
    }),

  // Get user permissions
  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
